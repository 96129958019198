import React, { useState } from 'react'
import classNames from 'classnames'

interface Props {
  label?: string
  labelId?: string
  toggled?: boolean
}

const ContentDropdown: React.FC<Props> = ({ label, labelId, toggled, children }) => {
  const [dropdownActive, setDropdownActive] = useState(false)

  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive)
  }

  return (
    <div className={classNames('content-dropdown', { open: toggled || dropdownActive })}>
      <button className='content-dropdown-button' onClick={toggleDropdown}>
        <h3 id={labelId} className='content-dropdown-button-label'>
          {label}
        </h3>
        <i className='fas fa-chevron-down'></i>
      </button>
      <div className='content-dropdown-body'>{children}</div>
    </div>
  )
}

export default ContentDropdown
