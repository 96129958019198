const ListItem = ({ text, iconClass = 'fa-check-circle' }) => {
  return (
    <li className='list-item-common'>
      <div className='list-container-flex'>
        <i className={`fal ${iconClass} fa-icon-style`} />
        <div className='text-container'>
          <p className='text'> {text} </p>
        </div>
      </div>
    </li>
  )
}

export default ListItem
