import React from 'react'
import I18n from '../../helpers/I18n'
import classnames from 'classnames'

interface Props<T> {
  readonly preferredBucketSize?: 1 | 2 | 3 | 4 | 5
  readonly loading?: boolean
  readonly suggestionHeader?: React.ReactNode
  readonly carouselId: string | number
  readonly centerCarousel?: boolean
  readonly recommendedObjects: ReadonlyArray<T>
  readonly formComponent: (props: T) => React.ReactNode
  readonly marketingVariant?: boolean
}

interface State<T> {
  readonly formattedBucket: ReadonlyArray<ReadonlyArray<T>>
  readonly loadedData: boolean
  readonly resizing: boolean
}
class SuggestionCarousel<T> extends React.Component<Props<T>, State<T>> {
  private carousel: HTMLDivElement | null
  private setCarouselRef: (element: HTMLDivElement) => void
  constructor(props) {
    super(props)
    this.carousel = null
    //Sending true as initial state to signal component that it's in loading state
    this.state = {
      formattedBucket: [],
      loadedData: false,
      resizing: false,
    }
    this.updateCarouselSet = this.updateCarouselSet.bind(this)

    this.setCarouselRef = element => {
      this.carousel = element
      this.initializeCarousel()
    }
  }

  initializeCarousel() {
    if (this.props.recommendedObjects && this.props.recommendedObjects.length > 0) {
      this.updateCarouselSet(false, this.props)
    }
    window.addEventListener('resize', () => this.updateCarouselSet(true, this.props))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateCarouselSet(true, this.props))
  }

  updateCarouselSet(resizeUpdate: boolean, props: Props<T>) {
    const { preferredBucketSize } = props
    if (this.carousel && (this.state.loadedData == false || resizeUpdate)) {
      const lBuckets: Array<Array<T>> = []
      const width = this.carousel.offsetWidth
      let setNumber = 4
      if (!preferredBucketSize) {
        if (width > 1100) {
          setNumber = 4
        } else if (width <= 1100 && width > 860) {
          setNumber = 3
        } else if (width <= 860 && width > 760) {
          setNumber = 2
        } else {
          setNumber = 1
        }
      } else {
        setNumber = preferredBucketSize
      }
      const lRecommendedObjects: Array<T> = [...props.recommendedObjects]

      let z = 0
      while (lRecommendedObjects.length != 0) {
        lBuckets[z] = []
        for (let j = 0; j < setNumber; j++) {
          if (lRecommendedObjects.length != 0) {
            const x: T = lRecommendedObjects.shift() as T
            lBuckets[z].push(x)
          }
        }
        z++
      }
      this.setState({ formattedBucket: lBuckets, loadedData: true })
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.recommendedObjects &&
      this.props.recommendedObjects &&
      newProps.recommendedObjects.length !== this.props.recommendedObjects.length
    ) {
      this.updateCarouselSet(false, newProps)
    }
  }

  render() {
    const {
      suggestionHeader = <h4 className='carousel-heading'>{I18n.t('frontend.components.common.suggestion_carousel.recommended_for_you')}</h4>,
      carouselId,
      formComponent,
      marketingVariant,
    } = this.props

    const suggestionCarouselClasses = classnames(
      'container-fluid',
      'suggestion-carousel',
      carouselId && `suggestion-carousel-${carouselId}`
    )

    const isNotStaticCarousel = this.props.recommendedObjects.length <= 5

    return (
      <div ref={this.setCarouselRef}>
        {this.state.formattedBucket.length != 0 && (
          <div className={suggestionCarouselClasses}>
            {marketingVariant ? null : suggestionHeader}
            <div
              id={`additional_${carouselId}`}
              className='carousel slide'
              data-ride='carousel'
              data-interval='false'
            >
              <div
                className={classnames('carousel-inner', {
                  'carousel-no-slide': isNotStaticCarousel,
                })}
              >
                {this.state.formattedBucket.map((bucket, key) => (
                  <div className={`item ${key == 0 ? 'active' : ''}`} key={key + 1}>
                    <div
                      className={classnames('carousel-items', {
                        'carousel-items-no-slide': isNotStaticCarousel,
                      })}
                    >
                      {bucket.map((object, keyTwo) => (
                        <div className='carousel-item' key={keyTwo + 1}>
                          {formComponent(object)}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              {this.state.formattedBucket.length <= 1 || isNotStaticCarousel ? null : (
                <a
                  className='left flex-centering carousel-control'
                  data-slide='prev'
                  role='button'
                  href={`#additional_${this.props.carouselId}`}
                >
                  <div>
                    <span
                      className='fal fa-chevron-left black_color'
                      aria-hidden='true'
                      style={{ fontSize: '50px' }}
                    />
                    <span className='sr-only'>Previous</span>
                  </div>
                </a>
              )}
              {this.state.formattedBucket.length <= 1 || isNotStaticCarousel ? null : (
                <a
                  className='right flex-centering carousel-control'
                  data-slide='next'
                  role='button'
                  href={`#additional_${this.props.carouselId}`}
                >
                  <div>
                    <span
                      className='fal fa-chevron-right black_color'
                      aria-hidden='true'
                      style={{ fontSize: '50px' }}
                    />
                    <span className='sr-only'>Next</span>
                  </div>
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}
export default SuggestionCarousel
