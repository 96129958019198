import React from 'react'
import parameterize from 'parameterize'

interface ApplicationFloorPlanSectionProps {
  name: string
}

const ApplicationFloorPlanSection: React.FC<ApplicationFloorPlanSectionProps> = ({ name }) => {
  return (
    <div
      data-tip
      data-for={name}
      className={`applications-floor-plan-section applications-floor-plan-section-${parameterize(name)}`}
    ></div>
  )
}

export default ApplicationFloorPlanSection
