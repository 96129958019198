class NavItem {
  constructor(id, fontAwesomeClassName, mainSectionID, subSectionID, title, url) {
    this.id = id
    this.fontAwesomeClassName = fontAwesomeClassName
    this.mainSectionID = mainSectionID
    this.subSectionID = subSectionID
    this.title = title
    this.url = url
  }
}

export default NavItem
