class SubSection {
  constructor(id, title, expanded, children) {
    this.id = id
    this.title = title
    this.expanded = expanded
    this.children = children
  }
}

export default SubSection
