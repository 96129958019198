import React from 'react'
import SuggestionCarousel from '../../components/common/SuggestionCarousel'
import DesignCard from '../../components/designs/DesignCard'
class MarketingCustomSuggestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = { recommendedObjects: this.props.carouselData, loading: false }
  }

  render() {
    return (
      <div style={{ marginBottom: '20px' }}>
        <SuggestionCarousel
          recommendedObjects={this.state.recommendedObjects}
          loading={this.state.loading}
          formComponent={design => <DesignCard design={design} />}
          carouselId={'marketing'}
          marketingVariant={true}
        />
      </div>
    )
  }
}
export default MarketingCustomSuggestion
