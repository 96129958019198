const DoosanLogo = props => {
  return (
    <svg
      width={props.width ? props.width : '169px'}
      height={props.height ? props.height : '100%'}
      viewBox='0 0 221 30'
      version='1.1'
    >
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M208.573,0.556 L202.388,18.023 L202.255,18.023 L200.503,0.556 L186.739,0.556 L176.697,28.902 L188.211,28.902 L194.413,11.394 L194.546,11.394 L196.55,28.902 L210.048,28.902 L220.088,0.556 L208.573,0.556 Z M163.091,18.38 L156.209,18.38 L163.843,6.352 L163.976,6.352 L163.091,18.38 Z M158.222,0.556 L136.668,28.902 L149.77,28.902 L152.784,23.938 L162.577,23.938 L162.078,28.902 L175.177,28.902 L173.703,0.556 L158.222,0.556 Z M140.332,20.722 C144.001,10.363 122.917,13.1 124.899,7.503 C125.349,6.233 126.976002,5.002 129.623,5.002 C132.005,5.002 132.637,6.391 132.047,8.058 L131.808,8.734 L143.321,8.734 C145.472,2.659 141.848,0 131.128,0 C120.741,0 114.534,3.135 112.511,8.853 C108.955,18.897 129.258,16.117 127.276,21.715 C126.727,23.262 124.85,24.455 122.336,24.455 C119.953,24.455 118.608,23.582 119.467,21.159 L119.705,20.484 L107.794,20.484 L107.445,21.478 C105.362,27.353 110.307,29.459 120.498,29.459 C131.349,29.459 138.193,26.757 140.332,20.722 Z M96.232,14.727 C93.518,22.391 92.39,24.455 88.619,24.455 C84.847,24.455 85.18,22.391 87.895,14.727 C90.608,7.065 91.738,5.002 95.509,5.002 C99.28,5.002 98.945,7.065 96.232,14.727 Z M97.28,0 C84.708,0 78.945,4.13 75.19,14.727 C71.437,25.33 74.275,29.459 86.847,29.459 C99.418,29.459 105.182,25.33 108.937,14.727 C112.69,4.13 109.851,0 97.28,0 Z M60.651,14.727 C57.937,22.391 56.809,24.455 53.037,24.455 C49.266,24.455 49.6,22.391 52.313,14.727 C55.028,7.065 56.156,5.002 59.927,5.002 C63.7,5.002 63.365,7.065 60.651,14.727 Z M61.7,0 C49.126,0 43.365,4.13 39.609,14.727 C35.855,25.33 38.693,29.459 51.265,29.459 C63.838,29.459 69.601,25.33 73.355,14.727 C77.11,4.13 74.271,0 61.7,0 Z M25.135,14.727 C22.42,22.391 21.403,23.582 16.638,23.582 L14.19,23.582 L20.462,5.874 L22.911,5.874 C27.675,5.874 27.848,7.065 25.135,14.727 Z M27.905,0.556 L10.039,0.556 L2.84217094e-14,28.902 L17.865,28.902 C32.089,28.902 35.209,22.152 37.838,14.727 C40.469,7.303 42.13,0.556 27.905,0.556 L27.905,0.556 Z'
          id='Doosan_Logotype_1C_BlackOnly_RGB'
          fill='#181918'
        ></path>
      </g>
    </svg>
  )
}
export default DoosanLogo
