import React from 'react'
import ModalButton from '../../components/common/ModalButton'
import DesignCustomizationModal from '../designs/DesignCustomizationModal'

export default class DesignCustomizationModalAndButton extends React.Component {
  render() {
    const { button_text = 'Request Design Review' } = this.props
    return (
      <ModalButton
        button={<button className='design-customization-button'>{ button_text }</button>}
        header={button_text}
        showCloseButton={true}
        width='720px'
      >
        {closeModal => (
          <DesignCustomizationModal
            closeModal={closeModal}
            design={{
              id: this.props.id,
              design_number: this.props.design_number,
              name: this.props.name,
              version: this.props.version,
              is_collaborator: true,
            }}
          />
        )}
      </ModalButton>
    )
  }
}
