class CTALandingPage extends React.PureComponent {
  render() {
    const { headline, text, buttonText, buttonHandle, button, headlineSize } = this.props
    const { children, style } = this.props

    return (
      <div className='box-cta home-page' style={{ ...style }}>
        <h1
          className='title-headline'
          style={{
            fontSize: headlineSize ? headlineSize : 'auto',
            fontWeight: 'bold',
          }}
        >
          {' '}
          {headline}{' '}
        </h1>
        {text && (
          <p className='text' style={{ outline: 'none' }}>
            {text}
          </p>
        )}
        {button}
        {buttonText && (
          <button
            className={`button button-success button-md ${
              !buttonHandle && 'bring_to_sign_up_state'
            }`}
            onClick={buttonHandle}
          >
            {buttonText}
          </button>
        )}
        {children}
      </div>
    )
  }
}

export default CTALandingPage
