import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { ProductizedApplicationsByCategory } from '../../../../components/common/providers/DesignCategoriesProvider'
import ApplicationFloorPlanSection from './ApplicationFloorPlanSection'
import ApplicationItemRow from './ApplicationItemRow'
import ApplicationPopover from './ApplicationPopover'
import ResizeObserver from 'resize-observer-polyfill'

interface ApplicationPopoverSection {
  name: string
  tooltipPlace: string
  tooltipLeft: number
  tooltipTop: number
}

interface ApplicationFloorPlanProps {
  productizedApplicationsByCategory: ProductizedApplicationsByCategory
  applicationPopoverSections: ApplicationPopoverSection[]
  hideOnMobile?: boolean
}

const ApplicationFloorPlan: React.FC<ApplicationFloorPlanProps> = ({
  productizedApplicationsByCategory,
  applicationPopoverSections,
  hideOnMobile,
}) => {
  const applicationsFloorPlanRef = useRef<HTMLDivElement>(null)
  const calculatePerspectiveObserver = new ResizeObserver(entries => {
    entries.forEach(entry => {
      const element = entry.target as HTMLElement
      const elementHeight = entry.contentRect.height
      const heightToSubtract = elementHeight * 0.2
      element.style.perspective = `${elementHeight - heightToSubtract}px`
    })
  })

  useEffect(() => {
    applicationsFloorPlanRef.current &&
      calculatePerspectiveObserver.observe(applicationsFloorPlanRef.current)

    return () => {
      applicationsFloorPlanRef.current &&
        calculatePerspectiveObserver.unobserve(applicationsFloorPlanRef.current)
    }
  }, [])

  return (
    <div className={classNames('applications-floor-plan-wrapper', { 'no-mobile': hideOnMobile })}>
      {applicationPopoverSections.map(({ name, tooltipLeft, tooltipPlace, tooltipTop }, index) => {
        const items = productizedApplicationsByCategory[name]
        if (!items) return null

        return (
          <ApplicationPopover
            key={index}
            name={name}
            tooltipPlace={tooltipPlace}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
          >
            <h2>{name} Applications</h2>
            <ApplicationItemRow detailed productizedApplications={items} />
          </ApplicationPopover>
        )
      })}

      <div className='applications-floor-plan' ref={applicationsFloorPlanRef}>
        <img
          src='https://assets.vention.io/page-assets/applications/application-floor-plan-v2.jpg'
          alt='Various applications by section on a floor'
          loading='lazy'
        />
        <div
          className={classNames('applications-floor-plan-sections', { 'no-mobile': !hideOnMobile })}
        >
          {applicationPopoverSections.map(({ name }, index) => (
            <ApplicationFloorPlanSection key={index} name={name} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ApplicationFloorPlan
