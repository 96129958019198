import React from 'react'
import SplittedContainerLandingPage from '../../../components/common/landing_page/SplittedContainerLandingPage'
import CTALandingPage from '../../../components/common/landing_page/CTALandingPage'
import ContainerBackgroundImageAndImage from '../../../components/common/ContainerBackgroundImageAndImage'
import ListLandingPage from '../../../components/common/landing_page/ListLandingPage'

const LeftRightSwappable = ({
  image,
  title,
  listText,
  includePadding,
  leftIsImage,
  ctaLabel,
  ctaLink,
}) => {
  const listArr = listText.split('<.>')
  const textSide = (
    <div className='hero-section' style={{ width: '100%' }}>
      <CTALandingPage headline={title} headlineSize={'2.5rem'}>
        {listArr.length > 1 ? (
          <ListLandingPage itemsText={listArr} />
        ) : (
          <p style={{ fontSize: '1.5714rem' }} dangerouslySetInnerHTML={{ __html: listText }}></p>
        )}
        {ctaLabel && ctaLabel.length > 0 && ctaLink && (
          <a className='button button-success button-md' href={ctaLink}>
            {ctaLabel}
          </a>
        )}
      </CTALandingPage>
    </div>
  )
  const imageSide = (
    <div className='image-hero-container' style={{ maxHeight: '100%' }}>
      <ContainerBackgroundImageAndImage
        style={{ padding: includePadding ? '40px' : 'auto' }}
        imageURL={image}
      />
    </div>
  )
  return (
    <SplittedContainerLandingPage
      firstHalf={leftIsImage ? imageSide : textSide}
      secondHalf={leftIsImage ? textSide : imageSide}
    />
  )
}
export default LeftRightSwappable
