import NovatechLogo from '../../components/svgs/NovatechLogo'
import SolidSmackLogo from '../../components/svgs/SolidSmackLogo'
import EngineeringLogo from '../../components/svgs/EngineeringLogo'

// Passing darkTheme = true as a prop will show the dark version of the component
const Quotes = props => {
  return (
    <div
      id='myCarousel'
      className={
        props.darkTheme
          ? 'quotes carousel slide flex-centering quotes-dark'
          : 'quotes carousel slide flex-centering '
      }
      data-ride='carousel'
      data-pause='false'
      data-interval='7000'
    >
      <div className='carousel-inner' role='listbox'>
        <div className='item'>
          <div className='quote-container'>
            <p className='quote'>
              "The machine design platform has become a valuable resource in optimizing for speed."
            </p>
            <p className='quote-name'>Marc-Antoine Dion</p>
            <p className='quote-company'>Product Development Technician, Novatech</p>
            <NovatechLogo />
          </div>
        </div>

        <div className='item active'>
          <div className='quote-container'>
            <p className='quote'>
              "I have never seen anything like this. Vention is about to shake the world of design
              automation and customization."
            </p>
            <p className='quote-name'>Josh Mings</p>
            <p className='quote-company'>Owner &amp; Editor, SolidSmack</p>
            <SolidSmackLogo />
          </div>
        </div>

        <div className='item'>
          <div className='quote-container'>
            <p className='quote'>
              "It's rare that I am caught off-guard by a new product. And I must admit, I am fairly
              impressed."
            </p>
            <p className='quote-name'>Phillip Keane</p>
            <p className='quote-company'>Tech Writer, ENGINEERING.com</p>
            <EngineeringLogo />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Quotes
