// NOTE: This is a temporary wrapper file to allow use of the FriendInviteMembersModalContent React component in ERB files
import React from 'react'
import FriendInviteMembersModalContent from '../../components/users/my_companies/FriendInviteMembersModalContent'

interface Props {
  currentUserId: number
  referralUrl: string
  platformReferralSource: string
}

const ErbFriendInviteMembers: React.FC<Props> = ({
  currentUserId,
  referralUrl,
  platformReferralSource,
}) => {
  return (
    <FriendInviteMembersModalContent
      platformReferralSource={platformReferralSource}
      currentUserId={currentUserId}
      referralUrl={referralUrl}
    />
  )
}

export default ErbFriendInviteMembers
