import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import I18n from '../../helpers/I18n'

interface Props {
  readonly showSeconds: boolean
  startTime: string
  isAfterCountdown: boolean
}

const ErbCountDownTimer: React.FunctionComponent<Props> = ({
  showSeconds = false,
  startTime,
  isAfterCountdown,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [days, setDays] = useState<number>(0)
  const [hours, setHours] = useState<number>(0)
  const [minutes, setMinutes] = useState<number>(0)
  const [seconds, setSeconds] = useState<number>(0)

  const currentTime = moment().unix()
  const eventTime = moment(startTime).unix()
  const interval = 1000
  const [diffTime, setDiffTime] = useState(eventTime - currentTime)

  let duration = moment.duration(diffTime * 1000, 'milliseconds')

  const setTimer = (): void => {
    duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds')
    const days = Math.max(0, Math.floor(moment.duration(duration).asDays())),
      hours = Math.max(0, moment.duration(duration).hours()),
      minutes = Math.max(0, moment.duration(duration).minutes()),
      seconds = Math.max(0, moment.duration(duration).seconds())

    setDays(days)
    setHours(hours)
    setMinutes(minutes)
    setSeconds(seconds)

    const updatedCurrentTime = moment().unix()
    setDiffTime(eventTime - updatedCurrentTime)
  }

  useEffect(() => {
    let intervalHandler: number

    if (diffTime > 0) {
      setTimer()
      intervalHandler = window.setInterval(setTimer, interval)
    } else if (diffTime <= 0) {
      window.location.reload()
    }

    setIsLoaded(true)
    return () => clearInterval(intervalHandler)
  }, [diffTime])

  return isAfterCountdown ? null : (
    <div className={`countdown-timer ${isLoaded ? 'countdown-timer-loaded' : ''}`}>
      <div className='countdown-timer-item'>
        <div className='countdown-timer-item-number'>{days}</div>
        {I18n.t('datetime.units.days')}
      </div>
      <div className='countdown-timer-item'>
        <div className='countdown-timer-item-number'>{hours}</div>
        {I18n.t('datetime.units.hours')}
      </div>
      <div className='countdown-timer-item'>
        <div className='countdown-timer-item-number'>{minutes}</div>
        {I18n.t('datetime.units.minutes')}
      </div>
      {showSeconds && (
        <div className='countdown-timer-item'>
          <div className='countdown-timer-item-number'>{seconds}</div>
          {I18n.t('datetime.units.seconds')}
        </div>
      )}
    </div>
  )
}

export default ErbCountDownTimer
