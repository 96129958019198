class ContainerBackgroundImageAndImage extends React.PureComponent {
  render() {
    const { backgroundImageURL, imageURL, style, imgStyle } = this.props

    return (
      <div
        className={`image-container`}
        style={{
          ...style,
          backgroundImage: backgroundImageURL && `url(${backgroundImageURL})`,
        }}
      >
        {imageURL && (
          <img
            style={{ ...imgStyle }}
            className='image-asset'
            alt='image-asset'
            src={`${imageURL}`}
          />
        )}
      </div>
    )
  }
}

export default ContainerBackgroundImageAndImage
