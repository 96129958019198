const BoeingLogo = props => {
  return (
    <svg
      width={props.width ? props.width : '167px'}
      height={props.height ? props.height : '38px'}
      viewBox='0 0 167 38'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Boeing-logo</title>
      <g id='Text-update-Jul-2020' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Text-Update'
          transform='translate(-1272.000000, -895.000000)'
          fill='#1C344F'
          fillRule='nonzero'
        >
          <g id='Boeing-logo' transform='translate(1272.000000, 895.000000)'>
            <path
              d='M26.4736586,17.4812795 C26.4736586,12.8689969 24.1238842,8.80355623 20.5617002,6.42450302 C22.6851746,4.23243318 24.9786672,2.06858845 27.4257624,0 C24.5612419,1.68756356 21.8667397,3.62914377 19.3762603,5.72125265 C17.5424042,4.75222698 15.4517622,4.20773714 13.2356573,4.20773714 C5.92602889,4.20773714 0,10.1489027 0,17.4812795 C0,20.7940793 1.21006359,23.8211102 3.20690333,26.1484197 C0.67421208,31.6415283 0.229818908,36.0632984 2.46351183,37.5568219 C4.85549801,39.1573618 10.0580679,36.2561635 13.1805472,31.5321604 C13.1805472,31.5321604 8.35084586,35.8998347 5.70207296,34.899056 C4.06637647,34.2793023 4.06285889,31.7779444 5.40776536,28.1876087 C7.60041913,29.8046122 10.3078195,30.7548218 13.2356573,30.7548218 C16.8623303,30.7548218 20.144276,29.2942263 22.5374346,26.926933 L23.0064508,27.8148153 C28.775359,27.346766 46,26.8916528 46,26.8916528 C46,26.0555159 34.597031,26.8116818 25.2401441,23.081405 C26.0292647,21.3820807 26.4736586,19.4840125 26.4736586,17.4812795 Z M1.58996729,17.4812795 C1.58996729,11.0297293 6.80543535,5.80357351 13.2356573,5.80357351 C14.9604667,5.80357351 16.596163,6.1787179 18.0688761,6.84903959 C11.757081,12.4444604 6.87109836,18.9489312 3.97843583,24.5678723 C2.47992683,22.6015958 1.58996729,20.1484537 1.58996729,17.4812795 M13.2356573,29.1636902 C10.5153593,29.1636902 8.01549922,28.2287683 6.03272982,26.6576287 C8.33677541,21.4397049 13.0363248,14.435433 19.443095,7.59815317 C22.7133155,9.66674246 24.8836916,13.3194065 24.8836916,17.4812795 C24.8836916,19.2593956 24.4861994,20.9457837 23.7768111,22.4569476 C21.744796,21.5126169 19.8464498,20.3319099 18.2388944,18.8419148 L21.7530038,25.4498741 C19.6260119,27.7324956 16.5985082,29.1636902 13.2356573,29.1636902'
              id='Shape'
            ></path>
            <polygon
              id='Path'
              points='104.909533 16.3681572 107.039282 13.4400995 99.6181506 13.4376495 101.447954 10.9268584 108.867929 10.9268584 111 8 96.1866845 8 86 22 100.812154 22 102.940753 19.0707435 95.5265666 19.0707435 97.4872354 16.3681059'
            ></polygon>
            <polygon id='Path' points='115.432599 8 105 22 112.567401 22 123 8'></polygon>
            <polygon
              id='Path'
              points='140.037751 8 134.800667 15.0959238 133.192781 8 127.332045 8 117 22 121.958722 22 127.149996 14.9676256 128.744968 22 134.667955 22 145 8'
            ></polygon>
            <path
              d='M165.201711,8.0024496 L153.399506,8 C151.375716,8 149.586639,9.00702192 148.453355,10.5715023 L142.360115,18.9981157 C142.130005,19.3098131 142,19.7030307 142,20.1274186 C142,21.1596155 142.801921,22 143.795982,22 L155.597033,22 C157.617374,22 159.415663,20.9917795 160.54319,19.4296963 L162.013572,17.4036648 L163.714069,17.4036648 L165.847165,14.4581258 L156.806246,14.4581258 L153.950618,18.4034935 C153.658379,18.8063021 153.191265,19.0688469 152.670069,19.0688469 L150.591054,19.0688469 C150.327578,19.0688469 150.119332,18.8482614 150.119332,18.5845175 C150.119332,18.4718269 150.157329,18.3711246 150.215976,18.2920016 L155.060885,11.5965065 C155.348515,11.1865046 155.813339,10.9287547 156.339132,10.9287547 L158.420449,10.9287547 C158.68047,10.9287547 158.885272,11.1493402 158.885272,11.4166811 C158.885272,11.5245761 158.851867,11.626477 158.793226,11.7067987 L158.006257,12.7977398 L165.340923,12.7977398 L166.637583,11.0018837 C166.864232,10.6901869 167,10.2993665 167,9.87617717 C167,8.84038333 166.192322,8.00239719 165.201711,8.00239719'
              id='Path'
            ></path>
            <path
              d='M90,9.87393993 C90,8.83925606 89.1957348,8 88.2039299,8 L76.404633,8 C74.3807497,8 72.5881351,9.00470965 71.4571065,10.569324 L65.3612851,18.9978586 C65.1357737,19.3119812 65,19.702834 65,20.1260595 C65,21.1595441 65.8065615,22 66.7983664,22 L78.5988115,22 C80.6215466,22 82.416463,20.9904939 83.5463435,19.4282774 L89.6433185,11.0021409 C89.8676817,10.6892181 90,10.2971655 90,9.87393993 M81.7940023,11.7047183 L76.9534688,18.4031854 C76.6589196,18.8060286 76.1940751,19.0673977 75.669411,19.0673977 L73.5891472,19.0673977 C73.3337179,19.0673977 73.1231601,18.847992 73.1231601,18.5830262 C73.1231601,18.4703266 73.158808,18.3696157 73.2175068,18.2904858 L78.0591884,11.5944161 C78.3514303,11.1927722 78.8162748,10.9278063 79.342087,10.9278063 L81.4200545,10.9278063 C81.6777857,10.9278063 81.8883435,11.1472121 81.8883435,11.4145753 C81.8883435,11.5224795 81.8538438,11.6255884 81.7940023,11.7047183'
              id='Shape'
            ></path>
            <path
              d='M61.6352255,15.3422965 C63.0928643,15.0005993 64.3581672,14.1289717 65.2295097,12.9348288 L66.6350067,11.0021409 C66.8632748,10.6892181 67,10.2983642 67,9.87393993 C67,8.83925606 66.1900661,8 65.1901087,8 L57.589052,8 L50.1942284,8 L40,22 L47.2650437,22 L55.5149764,22 C57.5531253,22 59.3595368,20.9904939 60.4996992,19.4282774 L61.9376485,17.4548253 C62.1195616,17.2042478 62.2261634,16.8901252 62.2261634,16.5532245 C62.2261634,16.0568637 61.994421,15.619251 61.6352255,15.3422965 M54.7710914,17.1478971 L53.856878,18.4055839 C53.5614102,18.8060286 53.0944571,19.0697946 52.5637703,19.0697946 L49.5268175,19.0697946 L51.4931323,16.3673885 L54.3968287,16.3673885 C54.6540601,16.3673885 54.8672665,16.5855956 54.8672665,16.8529588 C54.8672665,16.9656584 54.8313783,17.0687673 54.7710914,17.1478971 M58.7338394,11.7047183 L57.9575142,12.7753704 C57.6608834,13.1782136 57.1950861,13.4407814 56.6644059,13.4407814 L53.6263001,13.4383316 L55.4570383,10.9277551 L58.3584232,10.9277551 C58.6179765,10.9277551 58.8276949,11.1471608 58.8276949,11.414524 C58.8276949,11.5224283 58.7940532,11.6255371 58.7338394,11.704667'
              id='Shape'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default BoeingLogo
