import classnames from 'classnames'
import React, { FunctionComponent } from 'react'
import { ProductizedApplicationItem } from '../../../../api/DesignCategories/Types'

interface Props {
  productizedApplications: ReadonlyArray<ProductizedApplicationItem>
  detailed?: boolean
}

const ApplicationItemRow: FunctionComponent<Props> = ({ productizedApplications, detailed }) => {
  return (
    <>
      <div className='application-row-wrapper'>
        <div className='applications-cards-wrapper'>
          {productizedApplications.map(
            (productizedApplication: ProductizedApplicationItem, i: number) => (
              <a
                href={`/${productizedApplication.productized_application.url_slug}`}
                key={`card-${i}`}
                data-analytic-id='home-application-map-card-link'
              >
                <div className='app-card'>
                  <img
                    className='app-card-thumbnail'
                    src={`${productizedApplication.productized_application.image_url}`}
                    loading='lazy'
                    alt={ `${productizedApplication.name} thumbnail`}
                  />
                  <div className={classnames('app-card-label', { bold: detailed })}>
                    {productizedApplication.name}
                  </div>
                  {detailed && <div className='app-card-details'>Click for details</div>}
                </div>
              </a>
            )
          )}
        </div>
      </div>
    </>
  )
}

export default ApplicationItemRow
