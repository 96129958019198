import React from 'react'
import GoogleLogo from '../svgs/GoogleLogo'
import ThyssenKrupLogo from '../svgs/ThyssenKrupLogo'
import BoeingLogo from '../svgs/BoeingLogo'
import UniversalRobotsLogo from '../svgs/UniversalRobotsLogo'
import DmgMoriLogo from '../svgs/DmgMoriLogo'
import StrykerLogo from '../svgs/StrykerLogo'
import FanucLogo from '../svgs/FanucLogo'
import DoosanLogo from '../svgs/DoosanLogo'

interface Props {
  color?: 'vention-blue' | 'white'
  heading?: string
}

const ClientList: React.FC<Props> = ({ color = 'vention-blue', heading }) => {
  return (
    <div className='client-list'>
      {heading && <h3 className='client-list-heading'>{heading}</h3>}
      <div className={`client-list-logos ${color}`}>
        <div className='svg-wrapper'>
          <FanucLogo width='100%' height='100%' />
        </div>
        <div className='svg-wrapper'>
          <DoosanLogo width='100%' height='100%' />
        </div>
        <div className='svg-wrapper'>
          <GoogleLogo width='80%' height='100%' />
        </div>
        <div className='svg-wrapper'>
          <UniversalRobotsLogo width='100%' height='100%' />
        </div>
        <div className='svg-wrapper'>
          <DmgMoriLogo width='90%' height='100%' />
        </div>
        <div className='svg-wrapper'>
          <StrykerLogo width='90%' height='100%' />
        </div>
        <div className='svg-wrapper'>
          <BoeingLogo width='100%' height='100%' />
        </div>
        <div className='svg-wrapper thyssen-krup-logo'>
          <ThyssenKrupLogo width='70%' height='100%' />
        </div>
      </div>
    </div>
  )
}

export default ClientList
