import I18n from '../../helpers/I18n'
import React, { forwardRef, useEffect, useState } from 'react'
import CareerService from '../../api/Careers/Service'
import ContentDropdown from '../../components/common/ContentDropdown'
import Loading from '../../components/common/Loading'
import { CareerJob, CareerJobDepartment } from '../../api/Careers/Types'

interface Props {
  location: string
  setLocation: React.Dispatch<React.SetStateAction<string>>
}

const CareersJobs = forwardRef<HTMLDivElement, Props & React.HTMLProps<HTMLDivElement>>(
  ({ location, setLocation }, ref) => {
    const [jobs, setJobs] = useState<
      ReadonlyArray<ReadonlyArray<string | ReadonlyArray<CareerJob>>>
    >([])
    const [displayedJobs, setDisplayedJobs] = useState<
      ReadonlyArray<ReadonlyArray<string | ReadonlyArray<CareerJob>>>
    >([])
    const [department, setDepartment] = useState<string>('')
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [departmentResponse, setDepartmentResponse] = useState<
      ReadonlyArray<CareerJobDepartment>
    >([])

    const singleResult = displayedJobs.length === 1
    const departments = I18n.t('careers.index.jobs.departments').sort((a, b) => a.label.localeCompare(b.label))
    departments.unshift(I18n.t('careers.index.jobs.deparments_default_select'))

    const checkMissingDepartments = departmentsToCheck => {
      const missingDepartments = departmentsToCheck.filter(
        ({ id: id1 }) => !departments.some(({ id: id2 }) => id2 === id1)
      )

      if (missingDepartments.length > 0) {
        console.error('Missing departments via Smart Recruiters.')
        // If missing departments un-comment the line below in order to see the new department objects to be added to the en.yml and fr.yml files of the careers page.
        // missingDepartments.forEach(department => console.error(JSON.stringify(department)))
      }
    }

    const fetchJobList = async (queryParams: null | string = null): Promise<void> => {
      setIsLoaded(false)

      try {
        const response = await CareerService.fetchJobList(queryParams)
        setJobs(response.jobs)
        setDisplayedJobs(response.jobs)
        setDepartmentResponse(response.departments)
        setIsLoaded(true)
      } catch (error) {
        toastr.error('An error occured while retrieving job listings')
        setIsLoaded(true)
      }
    }

    useEffect(() => {
      fetchJobList(`language=${I18n.locale}`)
    }, [])

    useEffect(() => {
      filterJobs(location, department)
    }, [location, department])

    useEffect(() => {
      checkMissingDepartments(departmentResponse)
    }, [departmentResponse])

    const filterJobs = (location, department) => {
      // Map over jobs array and filter positions: [['departmentId', [{}, {}, {}]], ['departmentId', [{}, {}, {}]]]
      const filteredJobs = jobs
        .map(job => {
          const departmentId = job[0] as string
          const positions = job[1] as ReadonlyArray<CareerJob>

          const filteredJob = [] as Array<string | ReadonlyArray<CareerJob>>
          let filteredPositions = [] as ReadonlyArray<CareerJob>

          if (department && location) {
            filteredPositions = positions.filter(
              position =>
                position.location.region === location && position.department.id === department
            )
          } else if (location) {
            filteredPositions = positions.filter(position => position.location.region === location)
          } else if (department) {
            filteredPositions = positions.filter(position => position.department.id === department)
          } else {
            filteredPositions = positions
          }

          // If filtered positions exist build and return new array: ['departmentId', [{}, {}, {}]]
          if (filteredPositions.length > 0) {
            filteredJob.push(departmentId)
            filteredJob.push(filteredPositions)

            return filteredJob
          }
        })
        .filter(element => element !== undefined) as ReadonlyArray<
        ReadonlyArray<string | ReadonlyArray<CareerJob>>
      > // Remove undefined elements from array

      setDisplayedJobs(filteredJobs)
    }

    const handleLocationChange = (event): void => {
      setLocation(event.target.value)
    }

    const handleDepartmentChange = (event): void => {
      setDepartment(event.target.value)
    }

    const resetFilters = () => {
      setLocation('')
      setDepartment('')
    }

    const renderPositionRow = (position, index): JSX.Element => {
      const position_url_id = position.ref.split('/').pop()

      return (
        <div className='career-jobs-positions-row' key={`position-${index}`}>
          <div>{position.name}</div>
          <div>
            {position.location.city}, {position.location.region}
          </div>
          <a
            className='career-jobs-positions-apply-button'
            href={`https://jobs.smartrecruiters.com/Vention/${position_url_id}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {I18n.t('careers.index.jobs.apply')}
          </a>
        </div>
      )
    }

    const renderJobRow = (job, index): JSX.Element | undefined => {
      const departmentId = job[0]
      const positions = job[1]

      const selectedDepartment = departments.find(({ id }) => id === departmentId)

      if (!selectedDepartment) return // skip departments that are not included in our list I18n.t('careers.index.jobs.departments')

      return (
        <div key={index}>
          <ContentDropdown
            label={selectedDepartment && selectedDepartment.label}
            toggled={singleResult}
          >
            <div className='career-jobs-positions-header'>
              <h4 className='career-jobs-positions-header-label'>
                {I18n.t('careers.index.jobs.position')}
              </h4>
              <h4 className='career-jobs-positions-header-label'>
                {I18n.t('careers.index.jobs.location')}
              </h4>
            </div>
            {positions.map(renderPositionRow)}
          </ContentDropdown>
        </div>
      )
    }

    const renderLocationSelect = (): JSX.Element => (
      <select
        className='form-control careers-jobs-location-select'
        value={location}
        onChange={handleLocationChange}
      >
        {I18n.t('careers.index.jobs.locations').map(({ value, label }, index) => (
          <option value={value} key={index}>
            {label}
          </option>
        ))}
      </select>
    )

    const renderDepartmentSelect = (): JSX.Element => (
      <select
        className='form-control careers-jobs-department-select'
        value={department}
        onChange={handleDepartmentChange}
      >
        {departments.map(({ id, label }, index) => (
          <option value={id} key={index}>
            {label}
          </option>
        ))}
      </select>
    )

    const renderNoJobsError = (): JSX.Element => (
      <div className='careers-jobs-error'>
        <h3>{I18n.t('careers.index.jobs.error')}</h3>
        <button className='button button-vention' onClick={resetFilters}>
          {I18n.t('careers.index.jobs.error_button_text')}
        </button>
      </div>
    )

    return (
      <div id='career-jobs' className='careers-jobs' ref={ref}>
        <div className='careers-jobs-header'>
          <h2 className='careers-jobs-heading'>{I18n.t('careers.index.jobs.title')}</h2>
          <div className='careers-jobs-select-wrapper'>
            {renderLocationSelect()}
            {renderDepartmentSelect()}
          </div>
        </div>
        {isLoaded ? (
          displayedJobs.length > 0 ? (
            displayedJobs.map(renderJobRow)
          ) : (
            renderNoJobsError()
          )
        ) : (
          <Loading />
        )}
      </div>
    )
  }
)

CareersJobs.displayName = 'CareersJobs'

export default CareersJobs
