import React, { createContext, useContext, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import DesignCategoryService from '../../../api/DesignCategories/Service'
import {
  DesignCategory,
  ProductizedApplicationItem,
  UseCase,
} from '../../../api/DesignCategories/Types'

const NEW_SESSION_EVENT_NAME = 'new_design_categories_context_created'

interface DesignCategoriesContextType {
  readonly isLoaded: boolean
  readonly designCategories: ReadonlyArray<DesignCategory>
  readonly productizedApplications: ReadonlyArray<UseCase>
  readonly productizedApplicationsByCategory: ProductizedApplicationsByCategory
}

const DesignCategoriesContext = createContext<DesignCategoriesContextType>(
  {} as DesignCategoriesContextType
)

DesignCategoriesContext.displayName = 'DesignCategoriesContext'

export const DesignCategoriesConsumer = DesignCategoriesContext.Consumer

export type ProductizedApplicationsByCategory = Record<
  string,
  ReadonlyArray<ProductizedApplicationItem>
>

interface Props {
  skipFetch?: boolean
}

const DesignCategoriesProvider: React.FC<Props> = ({ skipFetch, children }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [designCategories, setDesignCategories] = useState<ReadonlyArray<DesignCategory>>([])
  const [productizedApplications, setProductizedApplications] = useState<ReadonlyArray<UseCase>>([])

  const [productizedApplicationsByCategory, setProductizedApplicationsByCategory] = useState<
    ProductizedApplicationsByCategory
  >({})

  // needed to be able to quickly dispatch state updates
  const stateSetters = {
    isLoaded: setIsLoaded,
    designCategories: setDesignCategories,
    productizedApplications: setProductizedApplications,
    productizedApplicationsByCategory: setProductizedApplicationsByCategory,
  }

  const uuidGenerated = uuid()

  const receiveNewSessionContext = (args): void => {
    if (args.detail.originator !== uuidGenerated) {
      const stateKey = args.detail.key
      const stateValue = args.detail.value
      stateSetters[stateKey](stateValue)
    }
  }

  const dispatchEvent = (key, value): void => {
    window.dispatchEvent(
      new CustomEvent(NEW_SESSION_EVENT_NAME, {
        detail: { key, value, originator: uuidGenerated },
      })
    )
  }

  const fetchDesignCategories = async (): Promise<void> => {
    try {
      setIsLoaded(false)
      dispatchEvent('isLoaded', false)
      const response = await DesignCategoryService.fetchDesignCategories()
      setDesignCategories(response.design_categories)
      dispatchEvent('designCategories', response.design_categories)
      setProductizedApplications(response.use_cases)
      dispatchEvent('productizedApplications', response.use_cases)

      const productizedApplicationsByCategory = {}
      response.use_cases.forEach(useCase => {
        productizedApplicationsByCategory[useCase.name] = useCase.productized_applications
      })

      setProductizedApplicationsByCategory(productizedApplicationsByCategory)
      dispatchEvent('productizedApplicationsByCategory', productizedApplicationsByCategory)
    } catch {
      toastr.error('There was an error fetching productized applications')
    } finally {
      setIsLoaded(true)
      dispatchEvent('isLoaded', true)
    }
  }

  useEffect(() => {
    window.addEventListener(NEW_SESSION_EVENT_NAME, receiveNewSessionContext)

    if (!skipFetch) {
      fetchDesignCategories()
    }

    return () => window.removeEventListener(NEW_SESSION_EVENT_NAME, receiveNewSessionContext)
  }, [])

  const contextValue = {
    isLoaded,
    designCategories,
    productizedApplications,
    productizedApplicationsByCategory,
  }

  return (
    <DesignCategoriesContext.Provider value={contextValue}>
      {children}
    </DesignCategoriesContext.Provider>
  )
}

export const useDesignCategoriesContext = (): DesignCategoriesContextType => {
  const context = useContext(DesignCategoriesContext)
  if (!context) {
    throw new Error('useDesignCategoriesContext must be used within a DesignCategoriesProvider')
  }

  return context
}

export default DesignCategoriesProvider
