import React, { useState } from 'react'
import AddOnPartsModalOriginal, {
  Props as PropsOriginal,
} from '../../components/common/modals/AddParts/AddPartsModal'
import { Part } from '../../api/Parts/Types'
import CheckoutService from '../../api/Checkout/Service'
import { useAdditionalParts } from '../../hooks/useAdditionalParts'
import { appendQueryString } from '../../helpers/StringHelper'
import { CheckoutHasMissingMachineMotionSafetyEquipment } from '../../api/Checkout/checkout-cart-contents'
import I18n from '../../helpers/I18n'

interface Props extends Pick<PropsOriginal, 'currencySymbol' | 'currencyCode'> {
  parts: ReadonlyArray<Part>
  hasMissingMachineMotionSafetyEquipment: CheckoutHasMissingMachineMotionSafetyEquipment
  cartPath: string
  skipAddons: boolean
  redirectTo: string
}

const AddOnPartsModal: React.FC<Props> = ({
  parts,
  currencySymbol,
  currencyCode,
  hasMissingMachineMotionSafetyEquipment,
  cartPath,
  redirectTo,
}) => {
  const { additionalParts, additionalPartTotal, recomputePrice } = useAdditionalParts(parts)
  const [loading, setIsLoading] = useState(false)

  const confirmAddParts = (): void => {
    setIsLoading(true)

    const addedAdditionalParts = additionalParts.filter(addOnPart => addOnPart.quantity > 0)

    CheckoutService.addAdditionalParts(addedAdditionalParts, res => {
      if (undefined == res) {
        toastr.error('Something went wrong with adding the additional parts to your cart.', '', {
          timeOut: 3000,
        })
        setIsLoading(false)
        return
      }
      Turbo.visit(appendQueryString(cartPath, { skip_addons: true }))
    })
  }

  const redirectToNextStep = () => {
    Turbo.visit(redirectTo)
  }

  return (
    <AddOnPartsModalOriginal
      additionalParts={additionalParts}
      currencySymbol={currencySymbol}
      currencyCode={currencyCode}
      hasMissingMachineMotionSafetyEquipment={hasMissingMachineMotionSafetyEquipment}
      modalIsOpen={true}
      closeModal={redirectToNextStep}
      confirmAddParts={confirmAddParts}
      recomputePrice={recomputePrice}
      additionalPartTotals={additionalPartTotal}
      disabled={loading}
      addToCartLabel={loading ? I18n.t('views.common.buttons.adding') : undefined}
      skipLabel={I18n.t('views.checkout.carts.add_ons.skip')}
    />
  )
}

export default AddOnPartsModal
