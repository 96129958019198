import httpClient from '../httpClient'

const CheckoutService = {
  addCartDesign: function (designId, additionalQueryParams, cb) {
    const baseUrl = `/designs/${designId}/buy`
    const url = additionalQueryParams ? baseUrl + additionalQueryParams : baseUrl
    $.ajax({
      type: 'POST',
      url: url,
      success: res => {
        toastr.success('A new design is added', { timeOut: 10000 })
        cb(res)
      },
      error: res => {
        const errorMessage = res.responseJSON?.message
          ? res.responseJSON.message
          : 'There was a problem adding new design'

        toastr.error(errorMessage, {
          timeOut: 10000,
        })
      },
    })
  },

  fetchAdditionalTools: function (cb) {
    $.ajax({
      url: '/checkout/get_additional_parts',
      type: 'GET',
      success: function (res) {
        cb(res)
      },
      error: function (error) {
        cb(null, error)
        console.log('Error while fetching additional tools')
      },
    })
  },

  addAdditionalParts: async (additionalParts, cb) => {
    try {
      const response = await httpClient.post('/checkout/add_on_parts', {
        additional_parts: JSON.stringify(additionalParts),
      })
      cb(response)
    } catch (error) {
      toastr.error('Something went wrong with adding the additional parts.', {
        timeOut: 3000,
      })
      console.error(error)
      throw error
    }
  },
}

export default CheckoutService
