import React, { createRef } from 'react'
import Captcha from 'react-google-recaptcha'

interface Props {
  captcha_key: string
  form_name: string
}

const ErbCaptcha: React.FC<Props> = ({ captcha_key, form_name }) => {
  const hiddenInputRef = createRef<HTMLInputElement>()

  const setCaptcha = (value: string) => {
    if (hiddenInputRef && hiddenInputRef.current) {
      hiddenInputRef.current.value = value
    }
  }

  return (
    <div className='captcha'>
      <Captcha sitekey={captcha_key} onChange={(value: string) => setCaptcha(value)} />
      <input
        autoComplete='off'
        type='hidden'
        name={`${form_name}[captcha]`}
        id={`${form_name}_captcha`}
        ref={hiddenInputRef}
      ></input>
      <span className='has-error'></span>
    </div>
  )
}

export default ErbCaptcha
