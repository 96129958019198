import React from 'react'
import ReactTooltip, { Place } from 'react-tooltip'
import parameterize from 'parameterize'

interface ApplicationPopoverProps {
  name: string
  tooltipPlace: string
  tooltipLeft: number
  tooltipTop: number
}

const ApplicationPopover: React.FC<ApplicationPopoverProps> = ({
  name,
  tooltipPlace,
  children,
  tooltipLeft,
  tooltipTop,
}) => {
  return (
    <ReactTooltip
      id={name}
      className={`persistent-tooltip-on-hover application-popover application-popover-${parameterize(
        name
      )}`}
      place={tooltipPlace as Place}
      effect='solid'
      type='light'
      delayHide={100}
      delayShow={100}
      scrollHide={false}
      backgroundColor='transparent'
      overridePosition={({ left, top }, _currentEvent, currentTarget, node) => {
        const container = (currentTarget as HTMLElement).parentElement
        if (!container || !node) return { top, left }

        const { height, width } = container.getBoundingClientRect()
        const { height: contentHeight, width: contentwWdth } = node.getBoundingClientRect()
        left = tooltipLeft * width - (tooltipPlace === 'right' ? 0 : contentwWdth)
        top = tooltipTop * height - contentHeight / 2
        return { top, left }
      }}
    >
      <div className='application-popover-content'>{children}</div>
    </ReactTooltip>
  )
}

export default ApplicationPopover
