import React from 'react'
import PropTypes from 'prop-types'
import NavItem from '../models/common/NavItem'

class VentionNavigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderSectionsAlphabetically:
        props.orderSectionsAlphabetically == null ? false : props.orderSectionsAlphabetically,
      navItems: props.navigationList,
      selectedNavItem: props.selectedNavItem,
      showItemCount: props.showItemCount == null ? false : props.showItemCount,
    }

    if (this.state.selectedNavItem != null) {
      this.expandTreeToSelectedNavItem(this.state.selectedNavItem)
    }

    if (this.state.orderSectionsAlphabetically) {
      this.orderSectionsAlphabetically(this.state.navItems)
    }

    // Bind methods
    this.expandTreeToSelectedNavItem = this.expandTreeToSelectedNavItem.bind(this)
    this.getMainSectionItemCount = this.getMainSectionItemCount.bind(this)
    this.toggleMainSectionExpandedState = this.toggleMainSectionExpandedState.bind(this)
    this.toggleSubSectionExpandedState = this.toggleSubSectionExpandedState.bind(this)
  }

  expandTreeToSelectedNavItem(selectedNavItem) {
    let parentMainSection = this.state.navItems.find(
      mainSection => mainSection.id == selectedNavItem.mainSectionID
    )
    parentMainSection.expanded = true

    if (selectedNavItem.subSectionID != null) {
      let parentSubSection = parentMainSection.subSections.find(
        subSection => subSection.id == selectedNavItem.subSectionID
      )
      parentSubSection.expanded = true
    }
  }

  getMainSectionItemCount(mainSection) {
    let count = mainSection.children.length
    for (const subSection of mainSection.subSections) {
      count += subSection.children.length
    }
    return count
  }

  orderSectionsAlphabetically(navItems) {
    // Sort sub-sections alphabetically
    for (let mainSection of navItems) {
      mainSection.subSections.sort((a, b) => a.title.localeCompare(b.title))
    }
  }

  toggleMainSectionExpandedState(sectionID) {
    let mainSection = this.state.navItems.find(mainSection => mainSection.id == sectionID)
    mainSection.expanded = !mainSection.expanded
    this.forceUpdate()
  }

  toggleSubSectionExpandedState(mainSectionID, subSectionID) {
    let mainSection = this.state.navItems.find(mainSection => mainSection.id == mainSectionID)
    let subSection = mainSection.subSections.find(subSection => subSection.id == subSectionID)
    subSection.expanded = !subSection.expanded
    this.forceUpdate()
  }

  render() {
    return (
      <nav className='vention-navigation'>
        {this.state.navItems.map((mainSection, mainSectionKey) => {
          if (mainSection.title == 'Faqs') {
            mainSection.title = 'FAQs'
          }
          // Only show sections that have subsections / children
          return (
            (mainSection.subSections.length > 0 || mainSection.children.length > 0) && (
              <ul className='vention-navigation-section' key={mainSectionKey}>
                <li>
                  <div
                    className='title'
                    onClick={() => this.toggleMainSectionExpandedState(mainSection.id)}
                  >
                    <span>{mainSection.title}</span>
                    {this.state.showItemCount && (
                      <span className='item-count'>
                        ({this.getMainSectionItemCount(mainSection)})
                      </span>
                    )}
                    <span className='chevron'>
                      <i
                        className={
                          mainSection.expanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'
                        }
                      ></i>
                    </span>
                  </div>
                </li>
                {
                  // Display main section children
                  mainSection.expanded &&
                    mainSection.children.map((child, childKey) => {
                      return (
                        <li
                          className={
                            this.state.selectedNavItem != null &&
                            child.id == this.state.selectedNavItem.id
                              ? 'child selected'
                              : 'child'
                          }
                          key={childKey}
                        >
                          <span className='icon'>
                            <i className={child.fontAwesomeClassName}></i>
                          </span>
                          <a href={child.url}>{child.title}</a>
                        </li>
                      )
                    })
                }
                {
                  // Display subsections
                  mainSection.expanded && mainSection.subSections.length > 0 && (
                    <li>
                      {mainSection.subSections.map((subSection, subSectionKey) => {
                        // Only show non-empty subsections
                        return (
                          subSection.children.length > 0 && (
                            <ul className='vention-navigation-section' key={subSectionKey}>
                              <li
                                className='title'
                                onClick={() =>
                                  this.toggleSubSectionExpandedState(mainSection.id, subSection.id)
                                }
                              >
                                <span className='copy'>{subSection.title}</span>
                                {this.state.showItemCount && subSection.children.length > 0 && (
                                  <span className='item-count'>({subSection.children.length})</span>
                                )}
                                <span className='chevron'>
                                  <i
                                    className={
                                      subSection.expanded
                                        ? 'fas fa-chevron-up'
                                        : 'fas fa-chevron-down'
                                    }
                                  ></i>
                                </span>
                              </li>
                              {
                                // Display subsection children
                                subSection.expanded && subSection.children.length > 0 && (
                                  <ul className='vention-navigation-section'>
                                    {subSection.children.map(
                                      (subSectionChild, subSectionChildKey) => {
                                        return (
                                          <li
                                            className={
                                              this.state.selectedNavItem != null &&
                                              subSectionChild.id == this.state.selectedNavItem.id
                                                ? 'child selected'
                                                : 'child'
                                            }
                                            key={subSectionChildKey}
                                          >
                                            <span className='icon'>
                                              <i
                                                className={subSectionChild.fontAwesomeClassName}
                                              ></i>
                                            </span>
                                            <a href={subSectionChild.url}>
                                              {subSectionChild.title}
                                            </a>
                                          </li>
                                        )
                                      }
                                    )}
                                  </ul>
                                )
                              }
                            </ul>
                          )
                        )
                      })}
                    </li>
                  )
                }
              </ul>
            )
          )
        })}
      </nav>
    )
  }
}

function validateNavigationList(props, propName, componentName) {
  componentName = componentName || 'ANONYMOUS'

  if (props[propName]) {
    let navigationList = props[propName]

    if (typeof navigationList != 'object') {
      new Error(`${propName} in ${componentName} is not of the correct type.`)
    }

    for (let mainSection of navigationList) {
      if (mainSection.constructor.name != 'MainSection') {
        new Error(`${propName} in ${componentName} is not of the correct type.`)
        // Do not proceed with further validation -- the MainSection object can't be created if it isn't valid.
      }
    }
  }
  return null
}

VentionNavigation.propTypes = {
  orderSectionsAlphabetically: PropTypes.bool,
  navigationList: validateNavigationList,
  selectedNavItem: PropTypes.instanceOf(NavItem),
  showItemCount: PropTypes.bool,
}

export default VentionNavigation
