// This is also implemented as a ERB partial in app/views/partials/common/_social_links.html.erb
import React from 'react'
import { stripHttp } from '../../helpers/StringHelper'

interface SocialLinksProps {
  readonly socialLinks: SocialLinks
  readonly whiteIcons?: boolean | undefined
  readonly type?: 'normal' | 'standardize'
}

interface LinkSocialIconProps {
  readonly link: string
  readonly whiteIcon?: boolean
  readonly parentClass: string
  readonly iconClass: string
}

const LinkSocialIcon: React.FC<LinkSocialIconProps> = ({
  link,
  whiteIcon,
  parentClass,
  iconClass,
}) => {
  return (
    <a
      className={`social-link ${whiteIcon ? 'white-icon' : parentClass}`}
      href={`//${stripHttp(link)}`}
      target='_blank'
      rel='noopener noreferrer'
    >
      <i className={iconClass} aria-hidden='true' />
    </a>
  )
}

const SocialLinks: React.FC<SocialLinksProps> = ({ socialLinks, whiteIcons, type = 'normal' }) => {
  const socialIcons = {
    linkedin: {
      parentClass: 'linkedin-icon',
      iconClass: 'fab fa-linkedin-in',
    },
    facebook: {
      parentClass: 'facebook-icon',
      iconClass: 'fab fa-facebook-f',
    },
    google: {
      parentClass: 'google-icon',
      iconClass: 'fab fa-google-plus-g',
    },
    youtube: {
      parentClass: 'youtube-icon',
      iconClass: 'fab fa-youtube',
    },
    instagram: {
      parentClass: 'instagram-icon',
      iconClass: 'fab fa-instagram',
    },
  }
  const modifierClass = type === 'standardize' ? 'social-links-standardize' : ''
  return (
    <div className={`social-links ${modifierClass}`}>
      {Object.keys(socialLinks).map(key => (
        <div key={key}>
          <LinkSocialIcon
            link={socialLinks[key] || ''}
            whiteIcon={whiteIcons}
            parentClass={socialIcons[key]?.parentClass || ''}
            iconClass={socialIcons[key]?.iconClass || ''}
          />
        </div>
      ))}
    </div>
  )
}

export default SocialLinks
