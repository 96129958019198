import React from 'react'
import LazyLoadBackground from '../../common/LazyLoadBackground'
import I18n from '../../../helpers/I18n'

const APPLICATIONS_CARD_DATA = {
  defaultCards: [
    {
      image:
        'https://assets.vention.io/page-assets/main-landing-page/discover-proven-applications/rpab-apppage-fanuc-mobile-pedestal-render.png',
      label: I18n.t('home.index.applications.names.pedestals_workstations'),
      redirect: '/robot-pedestal',
    },
    {
      image:
        'https://assets.vention.io/page-assets/main-landing-page/discover-proven-applications/range-extender-hero-ur-floor-mounted-7th-axis-optimized.jpeg',
      label: I18n.t('home.index.applications.names.range_extender_7th_axis'),
      redirect: '/range-extender',
    },
    {
      image:
        'https://assets.vention.io/page-assets/main-landing-page/discover-proven-applications/milling-drilling-table-config-lower-feature-view-optimized.jpeg',
      label: I18n.t('home.index.applications.names.milling_drilling'),
      redirect: '/milling-drilling',
    },
    {
      image:
        'https://assets.vention.io/page-assets/applications/renders/machine-tending.png',
      label: I18n.t('home.index.applications.names.machine_tending'),
      redirect: '/machine-tending',
    },
    {
      image:
        'https://assets.vention.io/page-assets/main-landing-page/discover-proven-applications/safety-enclosure-part-43-2-full-1035.png',
      label: I18n.t('home.index.applications.names.safety_enclosures'),
      redirect: '/safety-enclosure',
    },
    {
      image:
        'https://assets.vention.io/page-assets/main-landing-page/discover-proven-applications/doosan-cobot-palletizer-vertical-gantry-conveyor-square.png',
      label: I18n.t('home.index.applications.names.cobot_palletizer'),
      redirect: '/cobot-palletizer',
    },
  ],
  largeCard: {
    image: 'https://assets.vention.io/page-assets/main-landing-page/discover-proven-applications/pick-place-production-line-ur-doosan-fanuc-feature-view-bright-optimized.jpeg',
    label: I18n.t('home.index.applications.names.pick_and_place'),
    redirect: '/pick-place',
  },
}

interface Props {
  readonly onClickButton?: Function
  readonly title?: string
  readonly buttonText?: string
  readonly buttonUrl?: string
  readonly clickableTiles?: boolean
}

const HomeApplications: React.FC<Props> = ({
  onClickButton,
  title = 'Discover proven applications',
  buttonText = 'See all applications',
  buttonUrl = '/applications',
  clickableTiles = true,
}) => {
  const TileTag = clickableTiles ? 'a' : 'div'

  return (
    <section className='applications-section max-widescreen-page-width'>
      <h2 className='applications-section-heading'>{title}</h2>
      <div className='applications-section-card-wrapper wrapper'>
        <div className='applications-section-card-wrapper-left'>
          {APPLICATIONS_CARD_DATA.defaultCards.map((card, index) => (
            <TileTag key={index} className='applications-section-card' href={card.redirect}>
              <LazyLoadBackground
                className='applications-section-card-thumbnail'
                backgroundUrl={card.image}
              />
              <span className='applications-section-card-label'>{card.label}</span>
            </TileTag>
          ))}
        </div>
        <div className='applications-section-card-wrapper-right'>
          <TileTag
            className='applications-section-card applications-section-new-card'
            href={APPLICATIONS_CARD_DATA.largeCard.redirect}
          >
            <LazyLoadBackground
              className='applications-section-card-thumbnail applications-section-new-card-thumbnail'
              backgroundUrl={APPLICATIONS_CARD_DATA.largeCard.image}
            />
            <span className='applications-section-card-label applications-section-new-card-label'>
              {APPLICATIONS_CARD_DATA.largeCard.label}
            </span>
          </TileTag>
        </div>
      </div>
      <a
        className='button-blue'
        href={buttonUrl}
        onClick={() => {
          if (onClickButton) onClickButton()
        }}
      >
        {buttonText}
      </a>
    </section>
  )
}

export default HomeApplications
