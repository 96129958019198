import React from 'react'

class SplittedContainerLandingPage extends React.PureComponent {
  render() {
    const { reverse, height, direction } = this.props
    const { firstHalf, secondHalf, style } = this.props

    return (
      <div className='half-container'>
        <div
          className='half'
          style={{
            ...style,
            minHeight: `${height}`,
            flexDirection: reverse ? `${direction}-reverse` : `${direction}`,
          }}
        >
          <div className='half-child'>{firstHalf}</div>
          <div className='half-child'>{secondHalf}</div>
        </div>
      </div>
    )
  }
}

export default SplittedContainerLandingPage
