import { FetchJobListResponse } from './Types'
import httpClient from '../httpClient'

const CareerService = {
  fetchJobList: async function (queryParams: null | string = null): Promise<FetchJobListResponse> {
    try {
      let url = '/public_api/careers/jobs_list?'
      url = queryParams ? url + queryParams : url
      const response = await httpClient.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },
}

export default CareerService
