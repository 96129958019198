import ListItem from '../../common/ListItem'

class ListLandingPage extends React.PureComponent {
  render() {
    const { headline, itemsText } = this.props

    return (
      <div className='home-page'>
        {headline && <h1 className='title-headline'> {headline} </h1>}
        <ul className='list-unstyled'>
          {itemsText.map(item => (
            <ListItem key={item} text={item} />
          ))}
        </ul>
      </div>
    )
  }
}

export default ListLandingPage
