const GoogleLogo = props => {
  return (
    <svg
      width={props.width ? props.width : '104px'}
      height={props.height ? props.height : '34px'}
      viewBox='0 0 104 34'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Google-logo</title>
      <g id='Text-update-Jul-2020' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Text-Update'
          transform='translate(-595.000000, -898.000000)'
          fill='#1C344F'
          fillRule='nonzero'
        >
          <g id='Google-logo' transform='translate(595.000000, 898.000000)'>
            <path
              d='M45,18.5 C45,23.3938233 41.1835955,27 36.5,27 C31.8164045,27 28,23.3938233 28,18.5 C28,13.5716862 31.8164045,10 36.5,10 C41.1835955,10 45,13.5716862 45,18.5 Z M41.2791011,18.5 C41.2791011,15.4418395 39.067191,13.3494139 36.5,13.3494139 C33.932809,13.3494139 31.7208989,15.4418395 31.7208989,18.5 C31.7208989,21.5275023 33.932809,23.6505861 36.5,23.6505861 C39.067191,23.6505861 41.2791011,21.52367 41.2791011,18.5 Z'
              id='Shape'
            ></path>
            <path
              d='M63,18.5 C63,23.3938233 59.1835955,27 54.5,27 C49.8164045,27 46,23.3938233 46,18.5 C46,13.5755185 49.8164045,10 54.5,10 C59.1835955,10 63,13.5716862 63,18.5 Z M59.2791011,18.5 C59.2791011,15.4418395 57.067191,13.3494139 54.5,13.3494139 C51.932809,13.3494139 49.7208989,15.4418395 49.7208989,18.5 C49.7208989,21.5275023 51.932809,23.6505861 54.5,23.6505861 C57.067191,23.6505861 59.2791011,21.52367 59.2791011,18.5 Z'
              id='Shape'
            ></path>
            <path
              d='M81,10.5007006 L81,25.3797291 C81,31.5002335 77.3650047,34 73.0677328,34 C69.0225776,34 66.5879586,31.313405 65.6698024,29.1163008 L68.8607714,27.797291 C69.4289746,29.1461934 70.8212606,30.7379729 73.0639699,30.7379729 C75.8146754,30.7379729 77.519285,29.0527791 77.519285,25.8804297 L77.519285,24.6884633 L77.3913452,24.6884633 C76.5710254,25.6936011 74.9905927,26.5716955 72.9962371,26.5716955 C68.8231421,26.5716955 65,22.9621672 65,18.3176086 C65,13.6394208 68.8231421,10 72.9962371,10 C74.9868297,10 76.5672625,10.8780943 77.3913452,11.8533396 L77.519285,11.8533396 L77.519285,10.5044372 L81,10.5044372 L81,10.5007006 Z M77.7789276,18.3176086 C77.7789276,15.3993461 75.8184384,13.2657637 73.3236124,13.2657637 C70.79492,13.2657637 68.6763876,15.3993461 68.6763876,18.3176086 C68.6763876,21.2059785 70.79492,23.3096684 73.3236124,23.3096684 C75.8184384,23.3096684 77.7789276,21.2059785 77.7789276,18.3176086 Z'
              id='Shape'
            ></path>
            <polygon id='Path' points='86 1 86 26 83 26 83 1'></polygon>
            <path
              d='M101.074065,21.3013977 L103.846003,23.2328674 C102.951357,24.616321 100.795405,27 97.070154,27 C92.4502567,27 89,23.267358 89,18.5 C89,13.4452209 92.4795893,10 96.6704962,10 C100.890736,10 102.955023,13.5103697 103.629675,15.407349 L104,16.3730839 L93.1285749,21.0791253 C93.9608898,22.7844905 95.2551943,23.6544184 97.070154,23.6544184 C98.8887802,23.6544184 100.150086,22.7193417 101.074065,21.3013977 Z M92.5419213,18.2432372 L99.8090931,15.0892696 C99.4094353,14.0277277 98.2067954,13.2880974 96.7914935,13.2880974 C94.9765339,13.2880974 92.4502567,14.9628043 92.5419213,18.2432372 L92.5419213,18.2432372 Z'
              id='Shape'
            ></path>
            <path
              d='M13.0122187,15.4535424 L13.0122187,11.9015679 L24.8249143,11.9015679 C24.9403964,12.5206156 25,13.2529036 25,14.0455865 C25,16.710511 24.2810311,20.0058072 21.9639398,22.3536585 C19.7101773,24.7317073 16.8305767,26 13.015944,26 C5.94546267,26 0,20.1643438 0,13 C0,5.83565621 5.94546267,0 13.015944,0 C16.9274326,0 19.7139025,1.55516841 21.8074803,3.58217189 L19.3339294,6.08855981 C17.8326628,4.66173055 15.7986887,3.55197445 13.0122187,3.55197445 C7.84905379,3.55197445 3.81090747,7.76829268 3.81090747,13 C3.81090747,18.2317073 7.84905379,22.4480256 13.0122187,22.4480256 C16.361198,22.4480256 18.2685144,21.0853659 19.4903889,19.8472706 C20.4812994,18.8432056 21.1332141,17.4088269 21.3902548,15.4497677 L13.0122187,15.4535424 Z'
              id='Path'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
export default GoogleLogo
