import React, { ReactElement } from 'react'
import classNames from 'classnames'

interface Props {
  title: string
  logoUrl?: string
  className?: string
  subtitle?: string
  children?: ReactElement
  footer?: ReactElement
  bannerAccents: React.ReactNode
}

const BannerTemplate: React.FunctionComponent<Props> = ({
  title,
  logoUrl,
  bannerAccents,
  className,
  footer,
  subtitle,
  children,
}) => {
  return (
    <header className={classNames('generic-banner', className)}>
      <div className='outer-wrapper'>
        {bannerAccents}
        <div className='generic-banner-text-wrapper'>
          {logoUrl && <img className='generic-banner-logo' src={logoUrl} alt='Logo' />}
          <h1 className='generic-banner-title'>{title}</h1>
          {subtitle && <p className='generic-banner-subtitle'>{subtitle}</p>}
          {footer && <div className='generic-banner-footer'>{footer}</div>}
        </div>
      </div>
      {children}
    </header>
  )
}

export default BannerTemplate
