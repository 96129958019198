import React from 'react'
import TeamService from '../../../api/Teams/Service'
import { ValidatorForm } from 'react-form-validator-core'
import { removeArrayElement } from '../../../helpers/Collections'
import { handleStandardVentionError } from '../../../api/httpClient'
import FriendInviteMemberAccess from './FriendInviteMembersAccess'
import InviteWithLink from '../../common/InviteWithLink'

interface OwnProps {
  readonly closeModal?: () => void
  readonly teamId?: number
  readonly currentUserId?: number
  readonly referralUrl?: string
  readonly page?: 'dashboard' | 'dashboard/credits'
  platformReferralSource: string
}
interface State {
  newFriendsList: ReadonlyArray<string>
}

type Props = OwnProps

class FriendInviteMembersModalContent extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      newFriendsList: ['', '', ''],
    }
    this.addNewMemberEmailInput = this.addNewMemberEmailInput.bind(this)
    this.updateNewMembersEmailList = this.updateNewMembersEmailList.bind(this)
    this.removeNewMemberEmailInput = this.removeNewMemberEmailInput.bind(this)
    this.submitFriendInviteForm = this.submitFriendInviteForm.bind(this)
  }

  addNewMemberEmailInput(): void {
    const updatedList = [...this.state.newFriendsList, '']
    this.setState({
      newFriendsList: updatedList,
    })
  }

  updateNewMembersEmailList(pIndex, newValue): void {
    const updatedList: Array<string> = Object.assign([], this.state.newFriendsList)
    updatedList[pIndex] = newValue
    this.setState({ newFriendsList: updatedList })
  }

  removeNewMemberEmailInput(pIndex): void {
    const { newFriendsList } = this.state
    if (newFriendsList.length > 1) {
      const nextNewFriendsList = removeArrayElement(newFriendsList, pIndex)
      this.setState({
        newFriendsList: nextNewFriendsList,
      })
    } else {
      this.setState({
        newFriendsList: Object.assign(['']),
      })
    }
  }

  submitFriendInviteForm = async (): Promise<void> => {
    try {
      await TeamService.inviteFriends(this.state.newFriendsList, this.props.platformReferralSource)
      toastr.success('Email invites sent.')
      this.setState({
        newFriendsList: ['', '', ''],
      })
      if (this.props.closeModal) this.props.closeModal()
    } catch (e) {
      handleStandardVentionError(e, toastr.error)
      if (this.props.closeModal) this.props.closeModal()
    }
  }

  render(): React.ReactNode {
    const { newFriendsList } = this.state
    const isDashboard = this.props.page === 'dashboard'

    return (
      <div className='invite-members-container'>
        <div className='invite-members-marketing'>
          <div className='invite-members-marketing-title'>
            Get $100 off
            <div>your next order!</div>
          </div>
          <div className='invite-members-marketing-subtitle'>
            <div>
              Earn $100 for each friend <div>and colleague referred to Vention.</div>
            </div>
            <div>
              Apply earnings toward <div>purchase of $1,000 or more.</div>
            </div>
          </div>
        </div>
        <div className='invite-members-form'>
          <div className='modal-body'>
            <ValidatorForm
              id='invite-friends-form'
              className='form'
              onSubmit={this.submitFriendInviteForm}
              instantValidate={false}
            >
              <div className='emails-container'>
                <div className='invite-members-email-title'>
                  <span className='invite-members-email-title-icon'></span>
                  Invite through email
                </div>
                {newFriendsList.map((member, index) => (
                  <FriendInviteMemberAccess
                    key={index}
                    index={index}
                    email={member}
                    removeNewMemberEmailInput={this.removeNewMemberEmailInput}
                    updateNewMembersEmailList={this.updateNewMembersEmailList}
                  />
                ))}
              </div>
            </ValidatorForm>
            {newFriendsList.length < 15 && (
              <button onClick={this.addNewMemberEmailInput} className='invite-members-more-button'>
                <span className='invite-members-more-button-icon'></span>
                <span className='invite-members-more-button-text'>Invite more members</span>
              </button>
            )}

            {this.props.referralUrl && (
              <>
                <div className='invite-members-or'>or</div>
                <InviteWithLink
                  referralUrl={this.props.referralUrl}
                  referralType='referral-link-colleague'
                  buttonClass='invite-members-referal-copy-button'
                  buttonLabel='Copy'
                />
              </>
            )}
            <div className='invite-members-actions'>
              <button
                type='submit'
                form='invite-friends-form'
                className='invite-members-submit-button'
              >
                Share Vention
              </button>
              {isDashboard && (
                <button className='invite-members-dashboard-button' onClick={this.props.closeModal}>
                  Go to dashboard
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FriendInviteMembersModalContent
