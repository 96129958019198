import React, { useState } from 'react'
import copyToClipboard from 'copy-to-clipboard'

interface Props {
  readonly referralUrl: string
  readonly referralType: 'referral-link-team' | 'referral-link-colleague'
  readonly buttonClass?: string
  readonly buttonLabel?: string
}
const InviteWithLink: React.FunctionComponent<Props> = ({
  referralUrl,
  referralType,
  buttonClass = 'referral-link-copy-button',
  buttonLabel = 'Copy',
}) => {
  const [copied, setCopied] = useState(false)
  const referralText =
    referralType === 'referral-link-team'
      ? 'Share this link to invite to your team'
      : 'Share this link to refer colleagues'
  return (
    <div className='referral-link'>
      <h3 className='referral-link-title'>{referralText}</h3>
      <div className='referral-link-copy-wrapper'>
        <div className='referral-link-copy'>
          <span className='referral-link-copy-url truncate'>{referralUrl}</span>
          <button
            type='button'
            className={buttonClass}
            onClick={() => {
              copyToClipboard(referralUrl)
              setCopied(true)
            }}
          >
            {copied ? 'Copied!' : buttonLabel}
          </button>
        </div>
      </div>
    </div>
  )
}

export default InviteWithLink
