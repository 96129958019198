// NOTE: This is a temporary wrapper file to allow use of the TechDocPartDocuments React component in ERB files
import React from 'react'
import { User } from '../../api/Users/Types'
import { TechDocPartDownloads } from '../../components/cms/api/Types'
import TechDocPartDocuments from '../../components/cms/docs/tech_doc/sections/DocsTechDocPartDocuments'
import SignInProvider from '../../components/common/providers/SignInProvider'

interface Props {
  currentUser: User
  parts: TechDocPartDownloads[]
}

const ErbTechDocPartDocuments: React.FC<Props> = ({ currentUser, parts }) => {
  return (
    <SignInProvider currentUser={currentUser}>
      <TechDocPartDocuments type='partDocuments' parts={parts} />
    </SignInProvider>
  )
}

export default ErbTechDocPartDocuments
