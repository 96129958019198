import React, { ReactElement } from 'react'
import BannerTemplate from './BannerTemplate'

interface Props {
  title: string
  logoUrl?: string
  theme?: 'light' | 'dark'
  subtitle?: string
  children?: ReactElement
  accentAUrl?: string
  accentBUrl?: string
}

const GenericBanner: React.FunctionComponent<Props> = ({
  title,
  logoUrl,
  theme = 'dark',
  subtitle,
  accentAUrl,
  accentBUrl,
  children,
}) => {
  const bannerAccents = (
    <>
      <img className='outer-wrapper-accent-a' src={accentAUrl} alt='Banner Accent A' />
      <img className='outer-wrapper-accent-b' src={accentBUrl} alt='Banner Accent B' />
    </>
  )

  return (
    <BannerTemplate
      title={title}
      logoUrl={logoUrl}
      subtitle={subtitle}
      bannerAccents={bannerAccents}
      className={theme}
    >
      {children}
    </BannerTemplate>
  )
}

export default GenericBanner
