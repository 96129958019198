import React from 'react'
import VentionNavigation from '../../components/common/VentionNavigation'
import MainSection from '../../components/models/common/MainSection'
import NavItem from '../../components/models/common/NavItem'
import SubSection from '../../components/models/common/SubSection'
import { humanize } from '../../helpers/StringHelper'

class TechnicalDocumentsNavigation extends React.Component {
  constructor(props) {
    super(props)

    this.documentTypes = props.documentTypes
    this.mainCategories = props.mainCategories
    this.selectedDocument = props.selectedDocument
    this.subCategories = props.subCategories
    this.technicalDocuments = props.technicalDocuments

    this.state = this.buildTechnicalDocumentsNavigationList()
  }

  buildTechnicalDocumentsNavigationList() {
    let selectedNavItem = null
    let mainSections = this.buildMainSections()
    let subSections = this.buildSubSections()
    // Create navigation List for Technical Documents
    for (let technicalDocument of this.technicalDocuments) {
      const navItem = new NavItem(
        technicalDocument.id,
        this.getFontAwesomeClassName(technicalDocument.document_type),
        technicalDocument.main_category,
        technicalDocument.sub_category,
        technicalDocument.title,
        '/technical-documents/' + technicalDocument.url + '-' + technicalDocument.id
      )
      if (navItem.subSectionID != null) {
        let subSection = subSections.find(subSection => subSection.id == navItem.subSectionID)
        // Insert the navItem into the correct sub-section
        subSection.children.push(navItem)

        // Since subSection is not empty, push it into mainSection if it doesn't exist
        if (
          mainSections
            .find(navSection => navSection.id == navItem.mainSectionID)
            .subSections.find(navSection => navSection.id == subSection.id) == null
        ) {
          mainSections
            .find(navSection => navSection.id == navItem.mainSectionID)
            .subSections.push(subSection)
        }
      } else {
        // If no sub-section is specified, add the entity under its main category
        mainSections
          .find(navSection => navSection.id == navItem.mainSectionID)
          .children.push(navItem)
      }

      // Make selected nav item available in component
      if (this.selectedDocument != null && navItem.id == this.selectedDocument.id) {
        selectedNavItem = navItem
      }
    }

    return {
      navigationList: mainSections,
      selectedNavItem: selectedNavItem,
    }
  }

  buildMainSections() {
    let mainSections = []
    for (let key in this.mainCategories) {
      if (this.mainCategories[key] == 0) {
        // Skip 'None' main category
        continue
      }
      const mainSection = new MainSection(
        this.mainCategories[key], // Contains main category enum value (e.g. id=8 for main Category=['Machine Motion', 8])
        humanize(key), // Contains main category name (e.g. 'Machine Motion' for main Category=['Machine Motion', 8])
        false,
        [],
        []
      )

      mainSections.push(mainSection)
    }
    return mainSections
  }

  buildSubSections() {
    let subSections = []
    for (let key in this.subCategories) {
      const subSection = new SubSection(
        this.subCategories[key], // Contains main category enum value (e.g. id=8 for main Category=['Machine Motion', 8])
        humanize(key), // Contains main category name (e.g. 'Machine Motion' for main Category=['Machine Motion', 8])
        false,
        []
      )

      subSections.push(subSection)
    }
    return subSections
  }

  getFontAwesomeClassName(documentType) {
    // Get font awesome class name for every type of technical document
    switch (documentType) {
      case this.documentTypes.datasheet: {
        return 'fas fa-file-spreadsheet'
      }
      case this.documentTypes.how_to_guide: {
        return 'fal fa-file-invoice'
      }
      case this.documentTypes.user_manual: {
        return 'fal fa-file-user'
      }
      case this.documentTypes.white_paper: {
        return 'fal fa-file-alt'
      }
      case this.documentTypes.none:
      default: {
        return 'fal fa-file-invoice'
      }
    }
  }

  render() {
    return (
      <VentionNavigation
        orderSectionsAlphabetically={true}
        navigationList={this.state.navigationList}
        selectedNavItem={this.state.selectedNavItem}
        showItemCount={true}
      />
    )
  }
}

export default TechnicalDocumentsNavigation
