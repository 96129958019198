import React from 'react'
import DesignCategoriesProvider, {
  DesignCategoriesConsumer,
} from '../../components/common/providers/DesignCategoriesProvider'
import ApplicationFloorPlan from '../../pages/views/marketing/applications/ApplicationFloorPlan'

// calibrated according to the image and hover areas;
// the names must match the values returned from the database
const applicationPopoverSections = [
  {
    name: 'Manufacturing',
    tooltipPlace: 'right',
    tooltipLeft: 0.38,
    tooltipTop: 0.65,
  },
  {
    name: 'Packaging',
    tooltipPlace: 'right',
    tooltipLeft: 0.83,
    tooltipTop: 0.72,
  },
  {
    name: 'Quality Inspection',
    tooltipPlace: 'right',
    tooltipLeft: 0.38,
    tooltipTop: 0.22,
  },
  {
    name: 'Assembly',
    tooltipPlace: 'right',
    tooltipLeft: 0.81,
    tooltipTop: 0.35,
  },
]

const ErbApplicationFloorPlan: React.FC<{}> = () => {
  return (
    <DesignCategoriesProvider>
      <DesignCategoriesConsumer>
        {context => (
          <ApplicationFloorPlan
            productizedApplicationsByCategory={context.productizedApplicationsByCategory}
            applicationPopoverSections={applicationPopoverSections}
          />
        )}
      </DesignCategoriesConsumer>
    </DesignCategoriesProvider>
  )
}

export default ErbApplicationFloorPlan
